import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight } from '../../config';
import { GetDateWithSingleDatePiker, IsEmpty } from '../../config/appConstant';

const CssTextField = withStyles({
    root: {
        '& label': {
            color: BaseColor.inputLableColor,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.bold,
            letterSpacing: '0.5px',
        },
        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px',
        },
        '& label.Mui-focused': {
            color: BaseColor.inputMainColor,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: BaseColor.inputBorderColor,
                color: 'red',
            },
            '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.inputMainColor,
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.inputMainColor,
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 15px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.inputLableColor,
                fontWeight: FontWeight.regular,
            },
        },
    },
})(TextField);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    TextFieldStyle: {
        width: '100%',
        fontSize: 15,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.heavy,
        borderRadius: '8px',
        backgroundColor: BaseColor.inputBgColor,
    },
}));

export default function DatePiker(props) {
    const {
        value,
        onChange,
        error,
        label,
        name,
        onFocus,
        CalendarMonth,
        CalendarYear,
        IsSingleMonth,
        setFormValue,
        formvalue,
    } = props;
    console.log('value:', value)
    
    const [MinDate, setMinDate] = useState(null);
    const [MaxDate, setMaxDate] = useState(null);
    const [CurrentDay, setCurrentDay] = useState(null);


    const classes = useStyles();

    useEffect(() => {
        const today = new Date();

        setCurrentDay(today.getDate().toString().padStart(2, '0')); // Ensure 2-digit format
        if (!IsEmpty(CalendarYear) && !IsEmpty(CalendarMonth) && !IsEmpty(value) && value != 0) {

            setMinDate(`${CalendarYear}-${String(CalendarMonth).padStart(2, '0')}-01`);
            setMaxDate(null);
        } else if (IsSingleMonth) {
            const maxDate = new Date(CalendarYear, CalendarMonth, 0);
            const date = new Date(maxDate);
            const day = date.getDate();
            setMinDate(`${CalendarYear}-${String(CalendarMonth).padStart(2, '0')}-01`);
            setMaxDate(`${CalendarYear}-${String(CalendarMonth).padStart(2, '0')}-${day}`);

        } else {
            setMinDate(null);
            setMaxDate(null);
        }
    }, [CalendarYear, CalendarMonth, IsSingleMonth]);
    useEffect(() => {
        const today = new Date();
        setFormValue({ ...formvalue, 'AssetDate': `${CalendarYear}-${String(CalendarMonth).padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}` })
    }, [])

    return (
        <div className={classes.root}>
            <CssTextField
                error={error}
                className="fromcantrol-input"
                type="date" // Changed to "month" for month/year selection
                label={label}
                name={name}
                onFocus={onFocus}
                defaultValue={IsSingleMonth ? `${CalendarYear}-${String(CalendarMonth).padStart(2, '0')}-${CurrentDay}` : ''}
                variant="outlined"
                value={(IsEmpty(value) || value == 0) ? IsSingleMonth ? setFormValue({ ...formvalue, 'AssetDate': `${CalendarYear}-${String(CalendarMonth).padStart(2, '0')}-${CurrentDay}` }) : '' : GetDateWithSingleDatePiker(value)}
                onChange={onChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: MinDate, // Set minimum selectable month/year
                    max: MaxDate, // Set maximum selectable month/year
                }}
            />
        </div>
    );
}
